import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";

const NavBar = () => {
  return (
    <div className="navWrap">
      <Navbar expand="md" className="mx-3">
        <Navbar.Brand href="/">Psilo Sangha</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
            <Nav.Link href="/donate">Donate</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
