import { Image } from "react-bootstrap";
import News from "./News";

const Home = () => {
  return (
    <div className="home mx-3 mt-5">
      <div className="title">
        <h1 className="text-center mb-3">Psilo Sangha</h1>
        <h2 className="text-center mb-3">
          <i>~Wellness Center~</i>
        </h2>
        <Image fluid src="/logo.jpg" alt="" className="mb-5" />
        <p className="text-center mb-5">
          Providing safe space and comfortable atmosphere to help you achieve
          long lasting, holistic health and well-being. We support your journey
          into wellness with a combination of breakthrough discoveries in
          neuroscience and sincerely held spiritual beliefs and practices. Our
          friendly crew strives to help you discover your own inner tool set to
          identify and overcome obstacles to becoming your best self. We work
          with you to help you find your personal path forward into lasting
          health and happiness!
        </p>
      </div>

      <div className="values">
        <div className="value">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="75"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
          </svg>

          <div className="cardBtm">
            <h2 className=" pb-3">PURPOSE OVER PROFIT</h2>
            <p className=" mt-3">
              Our team comes from various cultural and religious backgrounds
              united by common spiritual and ethical values. As trained
              professionals, we see that the state programs are creating a very
              expensive for-profit industry which does not align with our
              values. It is our intention to make these services accessible by
              offering donation-based, sliding-scale, and otherwise affordable
              options.
            </p>
          </div>
        </div>
        <div className="value">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="75"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>

          <div className="cardBtm">
            <h2 className=" pb-3">KEEPING IT LEGAL</h2>
            <p className=" mt-3">
              Protecting the safety of all participating individuals and
              protecting the longevity of our church are extremely important
              priorities for us. It is with careful consideration that we have
              determined that the formation of a church body and ministry
              founded on sincerely held spiritual beliefs is the safest and most
              appropriate path forward. We do not require anyone to forsake
              their existing spiritual beliefs or anything of that nature.
              Anyone with a sincere interest in personal growth through direct
              communion with the Divine is invited to participate in our sacred
              prayer services for a very powerful healing experience, protected
              by the first amendment as well as the Religious Freedom
              Reformation Act of 1993.
            </p>
          </div>
        </div>
        <div className="value">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="75"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"
            />
          </svg>

          <div className="cardBtm">
            <h2 className=" pb-3">SUPPORTING SCIENCE</h2>
            <p className=" mt-3">
              Research is piling up in favor of alternative healing practices
              via courageous self-exploration and the application of sacred
              plant medicines. By creating an atmosphere of safety, trust, and
              compassionate care, the participating individual is able to make
              significant leaps forward in healing trauma, depression, anxiety,
              PTSD, existential crisis, and more. By consulting and exploring
              your intentions with our team, we will work with you to examine if
              our services are right for you and help you in creating a
              practical approach and taking steps towards lasting wellness!
            </p>
          </div>
        </div>
        <div className="value">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="75"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.707L8 2.207 1.354 8.853a.5.5 0 1 1-.708-.707L7.293 1.5Z" />
            <path d="m14 9.293-6-6-6 6V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9.293Zm-6-.811c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.691 0-5.018Z" />
          </svg>
          <div className="cardBtm">
            <h2 className=" pb-3">FROM THE HEART</h2>
            <p className=" mt-3">
              We believe that unselfish and infinite Love is at the core of our
              healing and awakening. With a shift in awareness those insidious
              fears, insecurities, and trances that often trouble our hearts can
              be dissolved as we rediscover our true self within the infinite
              and eternal expanses of loving presence. We are clear in our
              intention of putting genuine selfless, compassionate love, and
              respect into our practice so that you can have a safe place to
              realize a truly joyous life is yours right now.
            </p>
          </div>
        </div>
      </div>

      <div className="office">
        <Image fluid src="/office.jpg" alt="" className="my-5" />
      </div>
      <div className="missions">
        <div className="mission my-5">
          <div className="svgWrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="60"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
            </svg>
          </div>
          <h2 className="text-center mb-4">Mission</h2>
          <p>
            Our mission is to make sure that these services are provided with
            utmost regard for ethics and integrity. That includes making our
            services accessible and affordable to everyone with a sincere
            interest experiencing these unique personal journeys.
          </p>
        </div>
        <div className="mission missionCenter py-5">
          <div className="svgWrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="60"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="m2.68 7.676 6.49-6.504a4 4 0 0 1 5.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 0 1-5.653-5.66l.001-.002 1.505-1.492.001-.002Zm5.71-2.858a.5.5 0 1 0-.708.707.5.5 0 0 0 .707-.707ZM6.974 6.939a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707ZM5.56 8.354a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708Zm2.828 2.828a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707Zm1.414-2.121a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707Zm1.414-.707a.5.5 0 1 0-.706-.708.5.5 0 0 0 .707.708Zm-4.242.707a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707Zm1.414-.707a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708Zm1.414-2.122a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707ZM8.646 3.354l4 4 .708-.708-4-4-.708.708Zm-1.292 9.292-4-4-.708.708 4 4 .708-.708Z" />
            </svg>
          </div>
          <h2 className="text-center mb-4">Services</h2>
          <p>
            Our services are primarily focused on providing sacred ceremonial
            prayers. This requires consultation, advanced preparation,
            administration of prayer ceremony, and post-ceremony integration of
            the experience, all conducted with a mindful presence focused on the
            exploration of self and opening to the healing Divine presence.
            Other services such as a meditation, counseling, coaching and
            mindfulness practices are offered by trained and licensed
            professionals. Encouraging holistic health and well-being, we focus
            on providing specific tools which can be used for long-lasting
            recovery on the path of personal growth.
          </p>
        </div>
        <div className="mission my-5">
          <div className="svgWrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="60"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              <path
                fillRule="evenodd"
                d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
              />
              <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </svg>
          </div>
          <h2 className="text-center mb-4">Clients</h2>
          <p>
            No one is judged in this space. We serve veterans, law enforcement,
            spiritual seekers, professionals, depressed, traumatized, neglected,
            young, old, rich, poor, marginalized, LGBTQIA+, skeptics, curious,
            experienced and newbies from all backgrounds!{" "}
          </p>
        </div>
      </div>
      <div className="finalMessage text-center my-5">
        <h4 className="mb-4">
          Promoting Authentic And Meaningful Experiences For Healing In Our
          Communities
        </h4>
        <div className="consult">
          <a href="/contact" className=" text-center">
            Request A Consultation
          </a>
        </div>
      </div>
      <h4 className="donateHome">
        Psilo Sangha is a non-profit and your contribution helps us provide
        these valuable services to someone in need. If you'd like to contribute
        towards keeping Psilo Sangha open and available to all no matter what
        they have or where they are from, then please feel free to donate!
      </h4>
      <div className="donateDiv">
        <a
          href="https://donorbox.org/psilo-sangha-phaze-1"
          className="donateBtn"
        >
          Donate
        </a>
      </div>

      <div className="donateHome">
        <h4>
          To sign our liability waiver, please download this{" "}
          <a href="/liability_waiver">Liability Waiver</a>. You can then either
          sign it electronically and send it to{" "}
          <a href="mailto:psilo.sangha@gmail.com">psilo.sangha@gmail.com</a> or
          print it and bring a signed copy to our business
        </h4>
      </div>
      <News />
    </div>
  );
};

export default Home;
