const News = () => {
	return (
		<div className="news mb-4">
			<h3 className="newsTitle">Recent News</h3>
			<ul>
				<li>
					<a href="https://nbc16.com/news/douglas-county-oregon/majority-of-oregon-counties-will-see-magic-mushroom-back-on-the-ballot">
						Majority of Oregon counties will see magic mushroom back on the
						ballot
					</a>
					<span className="text-muted"> ~ Legislation 7/29/2022</span>
				</li>
				{/* <li>
				Check back later for more news updates
					<span className="text-muted"> ~ Similar Facilities</span>
				</li>
				<li>
					Check back later for more news updates
					<span className="text-muted"> ~ Research</span>
				</li> */}
			</ul>
		</div>
	);
};

export default News;
