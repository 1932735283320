const Donate = () => {
  return (
    <div className="donate">
      <div className="donateSide">
        <p>
          Psilo Sangha and our associates are addressing some important issues
          as the state program is finding its way forward. Specifically, the
          prohibitive costs of licensing and administration of the medicine are
          alarming. As it is currently, the costs for licensing facilitators and
          facilities are not in harmony with our intentions regarding this
          sacred medicine. In fact, it's outrageous. We believe that it is
          absolutely imperative that this medicine be available to everyone
          regardless of financial ability. That is the essence of this sacred
          medicine. These mushrooms are not expensive to produce, and they have
          a very long shelf life if properly stored. The sessions require no
          elaborate equipment and mostly self lead, as the mushrooms tend to
          know what to do. So when we see micro doses being offered for $400 and
          full journeys costing upwards of $3000, it hits a nerve. One of our
          most trusted associates has joined the ethics committee for the Oregon
          Psilocybin Program and will hopefully be able to help steer this ship
          on a more honorable course in tune with the integrity of our purpose.
          However, in the meantime and moving forward, any contributions or
          donations would be appreciated and applied towards keeping this
          medicine safe and available for all.
        </p>
      </div>
      <div className="donateAction">
        {/* <h2 className="pb-4 m-0">Donate</h2> */}
        <a
          href="https://donorbox.org/psilo-sangha-phaze-1"
          className="donateBtn"
        >
          Donate
        </a>
        {/* <a
          href="https://paypal.me/ianwallin93"
          className="facebook mb-0 text-muted justify-content-center"
        >
          Paypal{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#008cff"
            viewBox="0 0 16 16"
          >
            <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z" />
          </svg>
        </a>{" "}
        <a
          href="https://Venmo.com/Ian-Wallin-93"
          className="facebook mb-0 text-muted justify-content-center"
        >
          Venmo{" "}
          <img
            src="https://images.ctfassets.net/gkyt4bl1j2fs/ym6BkLqyGjMBmiCwtM7AW/829bf561ea771c00839b484cb8edeebb/App_Icon.png?w=276&h=276&q=50&fm=png&bg=transparent"
            alt=""
            height={"25px"}
          /> */}
        {/* </a>{" "} */}
      </div>
    </div>
  );
};

export default Donate;
