import Home from "./Home";
import Contact from "./Contact";
import About from "./About";
import Donate from "./Donate";
import { Liability } from "./Liability";

const Body = () => {
  let path = window.location.pathname;
  if (path === "/") {
    return <Home />;
  } else if (path === "/contact") {
    return <Contact />;
  } else if (path === "/about") {
    return <About />;
  } else if (path === "/donate") {
    return <Donate />;
  } else if (path === "/liability_waiver") {
    return <Liability />;
  }
  //  else if (path === "/contactSuccess") {
  // 	return <ContactSuccess />;
  // } else if (path === "/contactFail") {
  // 	return <ContactFail />;
  // }
  else {
    return <Home />;
  }
};

export default Body;
