import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer";
import Body from "./Body";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";

function App() {
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    const isConfirmed = localStorage.getItem("isConfirmed");
    if (isConfirmed === "confirmed") {
      setIsConfirmed("confirmed");
    } else {
      setIsConfirmed("unconfirmed");
    }
  }, []);

  if (isConfirmed === "confirmed") {
    return (
      <div className="App">
        <NavBar />
        <Body />
        <Footer />
      </div>
    );
  } else if (isConfirmed === "unconfirmed") {
    return (
      <>
        <div className="confirmAgeCont">
          <div className="confirmAge">
            <h1>Confirm Age</h1>
            <div className="">
              <h4>Are you at least 21 years old?</h4>
              <div className="confirmAgeBtm">
                <button
                  className="ageBtn"
                  onClick={() => {
                    localStorage.setItem("isConfirmed", "unconfirmed");
                    setIsConfirmed("notConfirmed");
                  }}
                >
                  No
                </button>
                <button
                  className="ageBtn"
                  onClick={() => {
                    localStorage.setItem("isConfirmed", "confirmed");
                    setIsConfirmed("confirmed");
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="notConfirmed">
          <h1>You must confirm your age to access this site.</h1>
        </div>
      </>
    );
  }
}

export default App;
