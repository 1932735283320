import { Image, Col, Row } from "react-bootstrap";

const About = () => {
  return (
    <div className="aboutIntro">
      <div className="aboutTitle mb-5">
        <h1 className="text-center mt-5 mb-3">About Us</h1>
        <Image src="/office.jpg" alt="" fluid className="mb-5" />{" "}
        <p>
          Along with our sacred prayer ceremonies, Psilo Sangha offers a variety
          of holistic healing tools and practices including licensed massage
          therapy, aroma therapy, coaching, addiction counseling, couples
          counseling, family counseling, meditation services, Reiki services,
          guided breath work, guided visualization, yoga, dance, and
          nutritional/dietary practices. The primary Psilo Sangha is
          conveniently located in the heart of downtown Ashland, Oregon, making
          it a practical spot to receive many of the same benefits of a remote
          retreat without having to pack your bags or empty or bank for an
          elaborate excursion.
        </p>
      </div>
      <div className="goals mb-5">
        <h4>We are here to...</h4>
        <ul>
          <li>
            Have deep personal experiences with the Divine, praying and
            expressing our gratitude for the teachings and insights received
            through these sacred ceremonies.{" "}
          </li>
          <li>
            Help bridge the gap between our preconceived "self" which suffers
            and struggles, and our deeper "self" which is whole and complete and
            always in the presence of the Divine.
          </li>
          <li>
            Build and strengthen our loving connections, forgiveness, and
            empathy within ourselves, letting that ripple into our lives and our
            communities.
          </li>
          <li>
            Help participating individuals to identify, examine and breakthrough
            barriers to healing.
          </li>
          <li>
            Help develop healthier thinking habits and skills for emotional
            processing.
          </li>
          <li>Assist in customizing a continued holistic healing practice.</li>
          <li>
            Provide our services safely, accessibly, and with loving compassion
            for all.
          </li>
        </ul>
      </div>
      <Row className="personal mb-5">
        <Col
          className="portrait d-flex align-items-center justify-content-center my-3"
          lg={{ order: 2, span: 6 }}
          xl={{ span: 6, offset: 1 }}
        >
          <Image src="/cover photo.jpg" alt="" fluid />
        </Col>
        <Col
          className="bio d-flex justify-content-center align-items-center flex-column my-3"
          lg={{ order: 1, span: 6 }}
          xl={{ span: 4, offset: 1 }}
        >
          <h3 className="mb-3">Ian Wallin</h3>
          <p>
            As a lifelong advocate for indigenous peoples and practices, I
            wholeheartedly recognize the ironies of "white savior complex" and
            "guru complex" and constantly examine myself to check my motives,
            values, and authenticity. If not for the support and encouragement
            of my elders in the native community, I may have never found this
            path which I walk with sincere gratitude and humility. I'll not
            write a lengthy biography about my experiences along the way, but
            I'm happy to share if asked and even happier to hear about your
            experiences and to understand what brings you to Psilo Sangha.
          </p>
        </Col>
      </Row>
      <Row className="personal">
        <Col
          className="portrait d-flex align-items-center my-3 justify-content-center"
          lg={6}
          xl={{ span: 4, offset: 1 }}
        >
          <Image src="/portrait2.jpg" alt="" fluid />
        </Col>
        <Col
          className="bio d-flex justify-content-center align-items-center flex-column my-3"
          lg={6}
          xl={{ span: 4, offset: 2 }}
        >
          <h3 className="mb-3">Robbie Armstrong</h3>
          <p>
            Robbie Armstrong is in the process of becoming a certified personal
            trainer/nutritionist. He already has a strong foundation in physical
            health and well-being and was the first "patient" at Psilo Sangha.
            During his session he had a profound life-changing experience which
            changed his world view! Prior to his session, he believed that Ian's
            meditation practice was "weird and unproductive". Now, Robbie is
            dedicated to sustainable living and developing his own personal
            meditation practice and he enthusiastically encourages others to try
            it out!
          </p>
        </Col>
      </Row>
      <div className="aboutFinalMessage text-center my-5">
        <h2>Helping YOU to shine more light into your life!</h2>
      </div>
    </div>
  );
};

export default About;
