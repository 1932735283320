export const Liability = () => {
  return (
    <div className="liability">
      <iframe
        title="Psilo Sangha Liability Waiver"
        src="/Psilo Sangha Liability Waiver.pdf"
      />
      <a
        href="/liability_waiver.pdf"
        download="Psilo Sangha Liability Waiver.pdf"
      >
        Download
      </a>
    </div>
  );
};
