import { Image } from "react-bootstrap";
const Contact = () => {
  return (
    <div className="contact mx-3 mb-3">
      <h1 className="text-center my-5">Send Us A Message!</h1>
      <ul className="mb-2">
        <li>
          <a className="contactLink" href="mailto:someoldnewbie@gmail.com">
            Email: someoldnewbie@gmail.com
          </a>
        </li>
        <li>
          <a className="contactLink" href="tel:5416259924">
            Phone: (541) 625-9924
          </a>
        </li>
      </ul>
      <h3 className="text-center mt-3 mb-1">or find us here at</h3>
      <p className="contactLink">21 South 2nd Street, Ashland Oregon 97520</p>
      <Image src="/waiting.jpg" alt="" fluid />
    </div>
  );
};

export default Contact;
